import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageFull } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'

const characters = [
  {name: 'Tamino', voice: 'Tenor', desc: 'A Japanese (per the libretto) prince who is seeking enlightenment and finds it after many trials.'},
  {name: 'Papageno', voice: 'Bass', desc: 'Lonely, talkative birdcatcher who is, in fact, part bird himself. He wants a wife more than anything. Papagei means "parrot" in German.'},
  {name: 'Pamina', voice: 'Soprano', desc: 'The Queen\'s daughter. She has a rough go, as she starts the opera a captive and gets manipulated repeatedly by Sarastro, but ends as the only person able to guide Tamino to salvation.'},
  {name: 'The Queen of the Night', voice: 'Soprano', desc: 'Vengeful mother whose daughter has been kidnapped. She starts the opera as the good woman and ends as the (mostly) bad woman.'},
  {name: 'Sarastro', voice: 'Bass', desc: ' Head Priest of the Sun. Starts off the opera as the bad guy and ends as the (mostly) good guy.'},
  {name: 'Three Ladies', voice: 'Soprano and Mezzo Soprano', desc: 'Servants of the Queen who try to get Tamino to do her bidding throughout the opera.'},
  {name: 'Monostatos', voice: 'Tenor', desc: 'Undeniable bad guy. A Moor (directors have to work hard to eliminate the narratively unnecessary but overt racism) who attempts to sexually assault Pamina several times.'},
  {name: 'Three Boys', voice: 'Treble, Alto and Mezzo', desc: 'Servants of Sarastro who guide Tamino and Papageno on their initiation journeys.'},
  {name: 'Papagena', voice: 'Soprano', desc: 'Bird-woman who is put forth as the much-desired wife sought by Papageno.'},
];

const inbrief = {name: 'Die Zauberflöte', 'translatedname': 'The Magic Flute', composer: "Wolfgang Amadeus Mozart", librettist: "Emanuel Schikaneder", language: "German", date: 'September 30th, 1791', acts: "Two", musiclength: "Two hours, thirty five minutes"};

const bibliography = [
  {name: 'Mozart: The Reign of Love', writer: 'Jan Swafford', url: 'https://amzn.to/2T2fLIO'},
  {name: 'The Magic Flute Unveiled: Esoteric Symbolism in Mozart\'s Masonic Opera', writer: 'Jacques Chailley', url: 'https://amzn.to/2IMVWmT'},
  {name: 'Mozart: His Character, His Work', writer: 'Alfred Einstein', url: 'https://amzn.to/3knnjmH'},
  {name: 'Stories of the Great Operas and their Composers', writer: 'Ernest Newman', url: 'https://amzn.to/2T3IjBt'},
  {name: 'The New Grove Book of Operas', writer: 'Stanley Sadie', url: 'https://amzn.to/34aKtFX'},
];

// markup
const FlutePage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Magic Flute (Die Zauberflöte) | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/flute" />
          <meta name="description" content="A guide to Mozart's masterpiece, The Magic Flute (Die Zauberflöte). Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="The Magic Flute" subtitle="An Opera by Wolfgang Amadeus Mozart"/>
        <MainBodySection>

          <p><i>The Magic Flute</i> (<i>Die Zauberflöte</i> in the original German) is, like so much of Mozart’s work, available to audiences on multiple levels. At the most basic, it is a fantastical evening full of sorcerers, heroes, magical instruments, wild animals, and special effects. For those who already know their way around the plot, the story holds a deeper meaning, subtly critiquing the time it was written and referencing the secret world of Freemasonry. Regardless of which camp you are in, it is a work full of gorgeous music, comical star turns, laughter, sorrow, and some genre-defining hits, from Papageno and Papagena’s love duet to the Queen of the Night’s fiery high notes.</p>

          <ImageFull>
            <StaticImage src="../../images/operas/flute/taymor_flute.jpeg" alt="Tamino tames some bears with his Magic Flute, in Julie Taymor’s production of The Magic Flute at the Metropolitan Opera House"/>
            <figcaption>Tamino tames some bears with his Magic Flute, in Julie Taymor’s production of The Magic Flute at the Metropolitan Opera House</figcaption>
          </ImageFull>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>
 
          <p>The piece is a German singspiel, meaning that unlike operas such as Mozart's <i>Le nozze di Figaro</i> or Rossini’s <i>Il barbiere di Siviglia</i>, there is no harpsichord accompanying the characters when they aren't singing. They speak their lines as if they were in a play. Many modern productions cut parts of this dialogue, particularly from scenes involving Monostatos, as they contain attitudes to race and gender that are unacceptable today.</p>
          
          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I – Running Time: 70 mins</SynopsisAct>

            <p>The opera opens with Tamino in a world of trouble, chased by a serpent. He calls for help ("Zu Hilfe! Zu Hilfe!"), then faints. The Three Ladies appear, kill the serpent, and rescue Tamino. He is beautiful, and they argue about who will guard his body while the other two tell the Queen of the Night. Unable to agree, they all leave.</p>

            <p>Tamino wakes up just as Papageno arrives. The birdcatcher sings to the audience ("Der Vogelfänger bin ich ja") and explains that he wishes he could catch a wife as well as birds. (Not great, Pap!) Despite his feathers, he tells Tamino that he’s "a man, like you," and says he catches birds for the Queen. Tamino asks if Papageno is the one who saved him from the serpent, and Papageno says, yep, that was me. The Three Ladies return and place a lock on Papageno’s mouth to teach him a lesson about lying.</p>

          </MainBodySection>

              <div id="ezoic-pub-ad-placeholder-104"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>            

            <p>They show Tamino a picture of Pamina, the Queen’s daughter, and he falls in love with her in "Dies Bildnis ist bezaubernd schön". They tell him that the evil Sarastro has kidnapped the girl, and the Queen will now tell him how to rescue her.</p>

            <VideoPlayer src="https://www.youtube.com/embed/I8_IyKKj4nw?autoplay=1" id="I8_IyKKj4nw" title="Jonas Kaufmann singing 'Dies Bildnis is bezaubernd schön' with the Munich Rundfunkorchester"/>

            <p>The mountains pull back, and the Queen appears on a throne of stars, accompanied by rumbling thunder. She sings the first of her two power numbers, "O zitt’re nicht, mein lieber Sohn". She begins grieving and impassioned, mourning the loss of her daughter. Still, after a minute or two, her anger takes over, and in a blaze of pyrotechnic runs ("Du wirst sie zu befreien gehen”), she tells Tamino that he will go get her daughter, ending on a high F, the limit of a coloratura soprano's range. She adds that if you rescue her, she will be yours, before disappearing with more thunder.</p>

            <p>The scene ends with a fun but moralizing quintet ("Hm hm hm hm") about how love would flourish if people couldn't lie, with Papageno only able to hum until the padlock is removed from his mouth. Tamino gets the titular Magic Flute to protect him on his journey, and Papageno terrified that Sarastro will eat him, gets magic silver bells. All they need to do is follow the Three Boys, who—in any production worth its weight—enter flying or floating above the stage.</p>

            <VideoPlayer src="https://www.youtube.com/embed/uRjPatWn5Yo?autoplay=1" id="uRjPatWn5Yo" title="Francisco Araiza, Manfred Hemm, Juliana Gondek, Mimi Lerner, and Judith Christin from the Metropolitan Opera in 1991."/>

          </MainBodySection>

              <div id="ezoic-pub-ad-placeholder-105"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <p>In Sarastro's place, his servants talk about how Pamina has escaped, but unfortunately for her, Monostatos enters, dragging her behind him. He chains her up and threatens to assault her, but Pamina rightfully says she would rather die, then faints. Papageno looks in the window, enters, and he and Monostatos scare each other with Monostatos running away. Pamina wakes up, and she and Papageno chat. He says, your mom sent me to rescue you, and I brought a Prince who loves you. She says, oooh, I love that word love! (Love in <i>The Magic Flute</i> happens as a matter of course, it is not lusty or romantic.) The two sing a duet to love, “the greatest joy”, in the very heteronormative "man and wife" sense.</p>

            <VideoPlayer src="https://www.youtube.com/embed/AOLnRcovLaA?autoplay=1" id="AOLnRcovLaA" title="Dame Kiri Te Kanawa and Thomas Allen do 'Bei Männern' on the 1983 variety show Kiri and Friends!"/>

            <p>Now on stage are three temples, to Reason, to Wisdom, and to Nature. Tamino tries to get in Reason and Nature but gets rebuffed. Then a priest emerges from the Temple of Wisdom. Tamino accuses Sarastro of kidnapping his love (whom he only knows from a photo, remember!), but the Priest defends his master's actions, saying his purpose will be clear in time. Left alone, Tamino plays his flute, which summons animals to him. (And in the original Vienna production, wild animals did indeed appear on stage.)</p>

            <p>Papageno and Pamina are trying to reach Tamino, as Monostatos catches up to them, but Papageno's magic pipes entrance him and his men and they dance off stage.</p>

            <p>Finally, Sarastro enters, in a chariot drawn by six lions, according to the libretto! (Sorry to say, but most opera houses today opt for other modes of transport.) The music clarifies that Sarastro may not be the evil sorcerer he has been made out to be. Pamina says sorry for trying to escape, and he forgives her, and then basically admits he hoped the kidnapping would make her love him. Life lesson learned. But, he said, you are still my prisoner because, basically, "A man must guide your heart because alone, women can't find wisdom." [This outrageous line is almost certainly Schikaneder's, as it is unlike anything else Mozart wrote. And Mozart had already cut one of Schikaneder's lines that said Papagena would become the property of her husband.]</p>

          </MainBodySection>

              <div id="ezoic-pub-ad-placeholder-106"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <p>Monostatos busts in again, now holding Tamino as his prisoner. Tamino and Pamina see each other for the first time and rush into each others’ arms. Monostatos makes his case, but Sarastro has his guards drag him away. Pamina and Tamino are veiled and separated. Tamino and Papageno led one way by Priests, Pamina taken into the Temple by Sarastro.</p>

            <SynopsisAct>Act II - Running Time: 85 mins</SynopsisAct>

            <p>The second half opens in a palm grove as priests file in. Sarastro tells the priests that he wants to initiate Tamino into the Brotherhood. The priests ask some questions then give their assent. Sarastro does a bit of ret-conning and tells them that Pamina is destined for Tamino, which is the real reason he kidnapped her, especially because her mom is trying to bewitch people and undermine the Temple. Sarastro adds that Pamina will be initiated too, then sings to the gods Isis and Osiris.</p>

            <VideoPlayer src="https://www.youtube.com/embed/oeP0NOPAIys?autoplay=1" id="oeP0NOPAIys" title="The great German bass René Pape singing Sarastro's 'O Isis und Osiris' in 2006 at the Metropolitan Opera."/>

            <p>Lot of initiation rites make up the rest of the opera. First, Tamino and Papageno are led on stage into the dark as thunder rumbles around them. Two Priests bless them and ask what they want. Tamino talks of love and friendship and wisdom; Papageno of food and drink, and maybe a wife. The Priests tell him about Papagena, who has feathers like him. Then he and Tamino are forbidden to talk to any women.</p>

            <p>The Priests leave, and the Three Ladies reappear and tell the two to flee; Sarastro is evil, remember the Queen. However, Tamino has drunk the Priests' Kool-Aid and says to ignore the Ladies because women can't be trusted. The Priests come back and curse the Ladies, who disappear through a trap door.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <p>The scene changes to a garden, and Monostatos comes across a sleeping Pamina and thinks about giving her just a little kiss. His brief patter aria "Alles fühlt der Liebe Freuden," which presages Rossini's "Largo al factotum," is musically charming despite its subject matter.</p>

            <p>He is run off by the Queen of the Night, who is about to unleash one of the highlights of this, or any, opera. She gives her daughter a dagger to kill Sarastro. "Der Hölle Rache" is a rage and vengeance-filled coloratura tour-de-force, encompassing not one but four high Fs! (It was a note that wasn't often sung when Mozart wrote the opera, but his sister-in-law, who debuted the role, had no problem with them.)</p>

            <VideoPlayer src="https://www.youtube.com/embed/YuBeBjqKSGQ?autoplay=1" id="YuBeBjqKSGQ" title="Diana Damrau singing 'Der Hölle Rache' at The Royal Opera House in 2003"/>

            <p>The Queen leaves in a flurry, and Monostatos shows up, again, steals the knife and says the only way Pamina can save herself is by...yep, you guessed it...loving him. She—like the audience—has had enough of him, screams "Götter!" and several neins and Monostatos is about to stab her when Sarastro shows up. In his unceasing mercy, he lets him go, again, and sings about how revenge has no home in this sacred place.</p>

            <p>Tamino and Papageno are led in again and reminded not to talk to anyone. Papageno ignores this and starts to chat up an older woman. However, she says she is really 18 years and two minutes old and has a sweetheart named Papageno. The Three Boys come in as the old woman leaves, returning the magic flute and bells and giving them food and drink.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <p>Tamino plays the flute, and Pamina enters. Remembering his vow, he ignores her, leading to her heartbroken aria, "Ach, ich fühl's", which ends with her saying that her only remaining friend is death. She leaves, and Tamino tells Papageno it is time for them to move on, but the birdcatcher wants to continue his feast. (Here, Mozart had the six lions come back to chase Papageno off stage.)</p>

            <p>More initiation rites. The Priests sing to the gods again, and Tamino and Pamina are led before Sarastro and told to say goodbye to each other, but if they make it through one more trial, they will meet again. The lovers are led off, and Papageno enters scared and alone. The Orator comes in and says he should be banished forever, but he has been forgiven. Papageno asks for a glass of wine and sings his light-hearted, well-known aria "Ein Mädchen oder Weibchen" about his dreams for a wife, accompanying himself on his magic bells.</p> 

            <VideoPlayer src="https://www.youtube.com/embed/O9yxFsGF6B8?autoplay=1" id="O9yxFsGF6B8" title="Stephen Dickson sings 'Ein Mädchen oder Weibchen' with New York City Opera in 1987."/>

            <p>The older woman enters. She says that if Papageno will love her, she will be his wife. He decides that an old wife is better than no wife, and the woman reveals herself to be the youthful feathered Papagena! Just as they are getting to know each other, they too are separated.</p>

            <p>For the finale, the Three Boys sing a glorious hymn-like melody to the sun then stop Pamina from killing herself in despair. The scene then reveals mountains, one erupting fire, the other with a waterfall. In the foreground are the doors of initiation, guarded by two Men in Armour. Tamino is prepped for his final trial. Pamina is brought in, and the men sing that she is the only one who can lead him on his journey. They are together now, finally. They enter the door in the rock, pass through fire, then water, and finally enter the Temple in triumph.</p>

            <p>Papageno is still despairing, however. He gets ready to hang himself when the Three Boys remind him of the magic bells. He plays them, and Papagena appears, and they sing to each other in bird-like glory.</p>

            <p>All that is left is for Monostatos, the Queen of the Night, and the Three Ladies to be defeated by light, which is exactly what happens. Then all those who have been initiated into the mysteries of the Brotherhood, having found wisdom and light, sing in triumph as Tamino and Pamina are crowned wearing their priestly robes.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-109"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Where and When"/>

            <p>While there are many references to Ancient Egypt in <i>The Magic Flute</i> and Tamino is specified as being Japanese, the opera is set in a non-specific magical land with monsters, magicians, and bird-people. In January 1790, when Holy Roman Emperor Joseph II was in the last months of his life, he reinstituted censorship, meaning any work of art had to be okayed by the state. The easiest way to get the official stamp of approval: make-believe.</p>
            
            <Header title="History"/>

            <p><i>The Magic Flute</i> was the last Mozart opera to premiere, but the second to the last that he composed. He wrote the music largely between May and July 1791, but the premiere was not until September 30. In between, he composed another opera, <i>La Clemenza di Tito</i>, premiered that opera in Prague, returned to Vienna, and commenced writing the <i>Requiem</i>, which would remain unfinished and feed so many (unfounded) rumours about his fate.</p>

            <p>The overture was not completed until two days before the premiere, meaning the orchestra likely had to sight-read it on opening night, a not uncommon practice in those days and certainly not for musicians working with Mozart.</p>

            <p>Emanuel Schikaneder had been friends with Mozart’s family for years before the two men collaborated on <i>The Magic Flute</i>. While Schikaneder is credited with the libretto, Mozart certainly helped. The work is cobbled together from different texts, including an unfinished piece Mozart began based on the 1731 novel Sethos, which was essential to Freemasons.</p>

            <p>The premiere took place at Schikaneder’s suburban Theater im Freihaus auf der Wieden. Mozart conducted from the harpsichord. Schikaneder was Papageno and Mozart’s sister-in-law Josepha Hofer was the Queen of the Night. Other family, friends, and troupe members completed the cast, including Benedikt Schack as Tamino (he also played flute and likely played Tamino’s numbers on stage) and 17-year-old Anna Gottlieb as Pamina (she had been the first Barbarina, at age 12, in Mozart’s <i>Le nozze di Figaro</i>).</p>
         
          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-110"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Bringing Deutsche back</FunFactsHeader>

            <p>Mozart only wrote two full-length operas in his native language, <i>Die Zauberflöte</i> and <i>Die Entführung aus dem Serail</i>, way back in 1782. He did, however, write a one-act German opera during those intervening years, the comic singspiel <i>Der Schauspieldirektor</i>, or The Impresario. That work was composed for a one-night event on February 7, 1786, in the Orangery hall of Schönbrunn Palace, on the outskirts of Vienna. Emperor Joseph's sister was in town, so he tasked two composers with the evening's entertainment: one was Mozart; the other was his favourite composer, Antonio Salieri. (Yep, that Salieri, the one who would be rumoured—with zero evidence—to have poisoned Mozart.)</p>

            <p>It was a night of inside jokes and digs at the Viennese music scene. On Mozart's part, he cast two sopranos: Aloysia Lange, his wife's younger sister (and Mozart's first love); and Caterina Cavalieri, Salieri's mistress. In duelling arias, each woman tries to prove she is the top prima donna, just as they were doing in their real-life careers. The highlight came when Aloysia ended her final appeal on a high note, in fact a high F, the same note that her big sister Josepha would deploy so memorably as The Queen of the Night.</p>

            <FunFactsHeader>Born to the theatre</FunFactsHeader>

            <p>Emanuel Schikaneder, who wrote the libretto for <i>Die Zauberflöte</i>, was a lifelong showman in the truest sense: singer, dancer, actor, musician, composer, director. He joined a travelling theatre group at age 18 and never looked back. At 26, he married the troupe's leading actress, Eleonore Arth, and before he turned 30, he was leading the company. Eleonore eventually left him because he philandered and began dating Johann Friedel, who ran the Theater im Freihaus auf der Wieden, on the outskirts of Vienna. When Friedel died in 1789, Eleonore inherited the theatre, patched things up with Schikaneder, and the two began running the house together. It was there that Mozart developed his friendship with Schikaneder and his theatre troupe, and it was there that they mounted Mozart's most tremendous operatic success, <i>The Magic Flute</i>.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-111"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <FunFactsHeader>It's Mozart Mostly</FunFactsHeader>

            <p>While none of Mozart's operas were smash hits during his lifetime, <i>The Magic Flute</i> came closest, playing 20 times in its first month and more than 200 times by the end of the century. And while today Mozart is renowned for his symphonies, piano concertos, string quartets, and so much more, the area he truly dominates is the opera stage. Since 2004, there have been more performances of Mozart operas than those of any other composer save one—Giuseppi Verdi, who wrote more than 20 operas. And yet, four of the 11 most-performed operas worldwide are by Mozart. That is two more than Verdi and one more than the other opera luminary, Giacomo Puccini. And while <Link to="/operas/giovanni">Don Giovanni</Link>, <i>Le nozze di Figaro</i>, and <i>Così fan tutte</i> are all considered masterpieces, Mozart's most popular opera today is the same as it was 230 years ago, <i>The Magic Flute</i>. It is the second most performed opera globally, staged 9,264 times in the past fifteen years.</p>

            <FunFactsHeader>Masonry motifs</FunFactsHeader>

            <p>Both Mozart and Schikaneder were Freemasons. However, the latter was only a member for a short time, and the mystical themes of the group greatly inspired <i>The Magic Flute</i>, beginning with its opening three chords. The number three is significant to Masons, and the chords echo the three knocks required to get into a Freemason meeting. The overture for the opera is also in the key of E-flat major, which has three flats. There are three Temples—to Reason, to Wisdom, and to Nature—to start the opera's second act, and there are numerous other examples. The whole opera has even been seen as an allegory about the plight of Masons in Viennese society, with Empress Marie Theresa being the Queen and her son Joseph II as Tamino.</p>

            <p>The one place where the opera upends Mason doctrine is by making Pamina both an initiate and essential to Tamino's initiation. Women were forbidden from joining the Masons, but Mozart didn't care in a mildly progressive move.</p>

            <FunFactsHeader>Audiences of all ages</FunFactsHeader>

            <p>While the themes of the opera may be mystical and the allegories many, this is, in the end, an opera about wizards, bird-people, magic instruments, and flying navigators, all backed by incredibly catchy tunes. As such, aside from the relatively long length, it is a perfect opera for children. The Metropolitan Opera House debuted a one-act, English-language version in 2006, and since 2013 it has made regular appearances around the Christmas holiday, ideal for the family who can't bear the thought of another Nutcracker or who want to expand their cultural appreciation.</p>

            <p>And it's not just the Americans who see Mozart's appeal to younger audiences. Since 2003, the Vienna State Opera has offered a one-hour version of <i>Die Zauberflöte</i> for children on the day after the Vienna Opera Ball.</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default FlutePage
